import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {CartComponent} from './cart/cart.component';
import {PolicyComponent} from './shared/policy/policy.component';
import { StatusComponent } from './status/status.component';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'product',
    pathMatch: 'full'
  },
  {
    path: 'product',
    loadChildren: () => import('./product/product.module').then(mod => mod.ProductModule),
  },
  {
    path: 'cart',
    component: CartComponent
  },
  {
    path: 'status',
    component: StatusComponent
  },
  {
    path: 'policy',
    component: PolicyComponent
  }
];
@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {scrollPositionRestoration: 'enabled', onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
