import { Component, OnInit } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'dfwm-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.scss']
})
export class PolicyComponent implements OnInit {
  public policy = [true, false, false ];

  constructor() {
  }
  ngOnInit(): void {}
  clickPolicy(index: number): void {
    for (let [i, value] of this.policy.entries()) {
      i === index ? this.policy[i] = true : this.policy[i] = false;
    }
  }

}
