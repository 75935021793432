<!-- Footer -->
<footer class="page-footer font-small unique-color-dark">
  <!-- Footer Links -->
  <div class="container text-center text-md-left mt-5">

    <!-- Grid row -->
    <div class="row mt-3">
      <!-- Grid column -->
      <div class="col-md-4 col-lg-4 col-xl-4 mx-auto mb-4">

        <!-- Links -->
        <h6 class="text-uppercase footer-title">이용 정보</h6>
        <hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;">
        <p>
          <a [routerLink]="['/policy']">이용약관</a>
        </p>
        <p>
          <a [routerLink]="['/policy']">개인정보 처리방침</a>
        </p>

      </div>
      <!-- Grid column -->

      <!-- Grid column -->
      <div class="col-md-8 col-lg-8 col-xl-8 mx-auto mb-md-0 mb-4">

        <!-- Links -->
        <h6 class="text-uppercase footer-title">판매자 정보</h6>
        <hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;">
        <p>(주)열정팩토리 서울시 서초구 잠원동 35-3 아람빌딩 2F</p>
        <p>대표 이정훈 | 사업자등록번호 114-87-18004 | 통신판매업 2020-서울서초-0815</p>
        <p>고객문의 support@diretfriends.kr</p>
        <p>유선문의 070-7756-1070 (10:00 ~ 19:00)</p>

      </div>
      <!-- Grid column -->

    </div>
    <!-- Grid row -->

  </div>
  <!-- Footer Links -->

  <!-- Copyright -->
  <div class="footer-copyright text-center py-3">© 2020 Copyright:
    <a href="https://dietfriends.kr/"> dietfriends.kr</a>
  </div>
  <!-- Copyright -->

</footer>
<!-- Footer -->
