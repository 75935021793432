import { Component } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'dfwm-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'web-market-client';
  constructor() {
  }
}
