<div class="dfwm-status">
  <div class="container dfwm-status__status_container">
      <div class="cart-nav">
        <button class="btn" [disabled]="items.length < 1">주문확인</button>
      </div>
      <h5>구매자 정보 입력</h5>
      <div class="card customer-card">
        <form [formGroup]="orderedForm">
          <div class="form-group">
            <!-- Username -->
            <label for="username">이름 <span class="lblError">*</span></label>
            <div class="controls">
              <input type="text" id="username" placeholder="Name" class="form-control" formControlName="name">
              <p class="lblError" *ngIf="orderedForm.get('name')?.hasError('required') && !orderedForm.get('name')?.pristine">
                이름을 입력해주세요
              </p>
            </div>
          </div>
          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <!-- E-mail -->
                <label for="email">이메일 <span class="lblError">*</span></label>
                <div class="controls">
                  <input type="email" id="email" placeholder="E-mail" class="form-control" formControlName="email">
                  <p class="lblError" *ngIf="orderedForm.get('email')?.hasError('required') && !orderedForm.get('email')?.pristine">
                    이메일을 입력해주세요
                  </p>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <!-- Mobile -->
                <label for="phone">연락처 <span class="lblError">*</span></label>
                <div class="controls">
                  <input type="text" id="phone" placeholder="Phone" class="form-control" formControlName="mobile">
                  <p class="lblError" *ngIf="orderedForm.get('mobile')?.hasError('required') && !orderedForm.get('mobile')?.pristine">
                    연락처를 입력해주세요
                  </p>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <h5>상품목록</h5>
      <div class="card cart-card" *ngIf="!hasItem" style="text-align: center; display: flex; justify-content: center; align-items: center;">
        <h1>조회된 상품이 없습니다.</h1>
      </div>
  </div>
</div>
