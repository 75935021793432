import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent, HeaderComponent } from './layout';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {RouterModule} from '@angular/router';
import { PolicyComponent } from './policy/policy.component';



@NgModule({
  declarations: [FooterComponent, HeaderComponent, PolicyComponent],
    exports: [
        HeaderComponent,
        FooterComponent,
        PolicyComponent,
    ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    RouterModule
  ]
})
export class SharedModule { }
