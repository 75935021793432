import { Injectable } from '@angular/core';
import {HttpClient, HttpParams, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Product, Image } from '../models';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  private API_BASE_URL = 'https://api.clayful.io/v1';
  private API_PUBLIC_TOKEN = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjBkNDkxYWQxYWFhOGJiYjMyZGNiOWE3NTJkOTRhZGJjOGU2YTA2MDk0NDY4NzI5ZjMzMmMyMmE3NjBiMGFmMGEiLCJyb2xlIjoiY2xpZW50IiwiaWF0IjoxNTczNTI4NTI2LCJzdG9yZSI6IldRSEdORlhLNFo0Ri5UOUxTUDNSVUZTSzciLCJzdWIiOiJTQVBWSlFVMzdCWEgifQ.VDi5-QQ0WV_CaemTFeBduD0zVDukhLzyyhDEqFvyNx4';
  private options = {
    headers: new HttpHeaders({
      Accept: 'application/json',
      Authorization: `Bearer ${this.API_PUBLIC_TOKEN}`
    }),
    params: new HttpParams()
  };
  constructor(private http: HttpClient) {}
  getProducts(): Observable<Product[]> {
    return this.http
      .get<Array<Product>>(`${this.API_BASE_URL}` + '/products', this.options)
      .pipe(map(resp => {
        return resp;
      }));
  }
  getProductDetail(id: string): Observable<Product> {
    return this.http
      .get<Product>(`${this.API_BASE_URL}` + '/products/' + id, this.options)
      .pipe(map(resp => {
        console.log(resp, 'it works');
        return resp;
      }));
  }
}
