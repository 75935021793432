<div class="dfwm-header__banner">
  <div class="container banner_container">
    <div class="row banner_row">
      <span class="banner__title_box">
        <p class="title__text">
          다이어트 프렌즈 앱을 다운받고 더 많은 혜택을 누리세요!
        </p>
      </span>
      <span class="banner__close_box">
        <a class="close__btn">
          <fa-icon [icon]="faTimes"></fa-icon>
        </a>
      </span>
    </div>
  </div>
</div>
<div class="dfwm-header__nav hidden">
  <div class="container nav_container">
    <div class="row nav_row nav_row-top">
      <div class="nav__user-menu_wrapper">
        <ul class="user-menu_ul">
          <li class="user-menu_li" *ngFor="let userMenu of userMenus; index as i">
            <a class="category__text" [routerLink]="['']">{{userMenu.name}}</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="row nav_row nav_row-center">
      <div class="nav__brand-logo_wrapper">
        <span class="brand-logo_box">
          <img class="brand-logo__img" src="../assets/img/main_logo.png" alt="df-brand-logo" />
        </span>
      </div>
    </div>
    <div class="row nav_row nav_row-bottom">
      <div class="nav__market-menu_wrapper">
        <ul class="market-menu_ul">
          <li class="market-menu_li" *ngFor="let marketMenu of marketMenus; index as i">
            <a class="market-menu__text" [href]="marketMenu.url" *ngIf="i === 0"><fa-icon [icon]="faBars"></fa-icon>{{marketMenu.name}}</a>
            <a class="market-menu__text" [href]="marketMenu.url" *ngIf="i !== 0">{{marketMenu.name}}</a>
          </li>
        </ul>
      </div>
      <div class="nav__market-action_wrapper">
        <ul class="market-action_ul">
          <li class="market-action_li">
            <a class="market-action__button"></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="dfwm-header__nav--easy">
  <div class="container nav_container">
    <div class="row nav_row">
      <div class="col-sm-6 nav_col nav_col-left">
        <div class="nav__brand-wrapper">
          <span class="brand_box">
            <a class="brand__text" [routerLink]="['']"><img class="brand__svg" src="../assets/logo_ko.svg" alt="brand-logo" /></a>
          </span>
        </div>
      </div>
      <div class="col-sm-6 nav_col nav_col-right">
        <div class="nav__easy-menu_wrapper">
          <ul class="easy-menu_ul">
            <li class="easy-menu_li">
              <a class="easy-menu__text" [routerLink]="['']">상품보기</a>
            </li>
            <li class="easy-menu_li">
              <a class="easy-menu__text" [routerLink]="['/cart']">장바구니</a>
            </li>
            <li class="easy-menu_li">
              <a class="easy-menu__text" [routerLink]="['/status']">주문조회</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
