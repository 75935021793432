import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'dfwm-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class StatusComponent implements OnInit {
  items: [] = [];
  customer = {
    name: '',
    email: '',
    mobile: '',
    address: '',
    request: '',
    privacy: false
  };
  loading!: boolean;
  hasItem = false;
  ordered: object = {};
  orderedForm!: FormGroup;
  constructor() { }

  ngOnInit(): void {
    this.orderedForm = new FormGroup({
      name: new FormControl(this.customer.name, [Validators.required]),
      email: new FormControl(this.customer.email, Validators.required),
      mobile: new FormControl(this.customer.mobile, Validators.required)
    });
  }

}
