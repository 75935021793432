import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {AppRoutingModule} from './app-routing';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from './shared';
import { CoreModule } from './core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import { CartComponent } from './cart/cart.component';
import { StatusComponent } from './status/status.component';
import {ReactiveFormsModule} from '@angular/forms';

@NgModule({
  declarations: [
    AppComponent,
    CartComponent,
    StatusComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    NgbModule,
    CoreModule,
    SharedModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    ReactiveFormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
