<div class="dfwm-cart" *ngIf="!loading">
  <div class="container dfwm-cart__cart_container">
    <section class="cart_section">
      <div class="cart-nav">
          <button class="btn" (click)="order()" [disabled]="items.length < 1">주문하기</button>
      </div>
      <h5>구매자 정보</h5>
      <div class="card customer-card">
        <form [formGroup]="customerForm">
          <div class="form-group">
            <!-- Username -->
            <label for="username">이름 <span class="lblError">*</span></label>
            <div class="controls">
              <input type="text" id="username" placeholder="Name" class="form-control" formControlName="name">
              <p class="lblError" *ngIf="customerForm.get('name')?.hasError('required') && !customerForm.get('name')?.pristine">
                이름을 입력해주세요
              </p>
            </div>
          </div>
          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <!-- E-mail -->
                <label for="email">이메일 <span class="lblError">*</span></label>
                <div class="controls">
                  <input type="email" id="email" placeholder="E-mail" class="form-control" formControlName="email">
                  <p class="lblError" *ngIf="customerForm.get('email')?.hasError('required') && !customerForm.get('email')?.pristine">
                    이메일을 입력해주세요
                  </p>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <!-- Mobile -->
                <label for="phone">연락처 <span class="lblError">*</span></label>
                <div class="controls">
                  <input type="text" id="phone" placeholder="Phone" class="form-control" formControlName="mobile">
                  <p class="lblError" *ngIf="customerForm.get('mobile')?.hasError('required') && !customerForm.get('mobile')?.pristine">
                    연락처를 입력해주세요
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <!-- E-mail -->
                <label for="address">주소 <span class="lblError">*</span></label>
                <div class="controls">
                  <input type="text" id="address" placeholder="Address" class="form-control" formControlName="address">
                  <p class="lblError" *ngIf="customerForm.get('address')?.hasError('required') && !customerForm.get('address')?.pristine">
                    주소를 입력해주세요
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <!-- E-mail -->
                <label for="request">요청사항</label>
                <div class="controls">
                  <input type="text" id="request" placeholder="주문시 요청사항을 입력해주세요" class="form-control" formControlName="request">
                </div>
              </div>
            </div>
          </div>
          <div class="form-group" >
            <div class="form-check form-check-inline">
              <!-- Gender -->
              <input type="checkbox" id="privacyCheck" class="form-check-input" formControlName="privacy">
              <label class="form-check-label" for="privacyCheck">개인정보 이용, 수집에 동의합니다.</label>
              <a style="margin-left: 0.5rem;" [routerLink]="['/policy']" target="_blank" href="/policy">개인정보 처리방침</a>
            </div>
          </div>
        </form>
      </div>
      <h5>상품목록</h5>
      <div class="card cart-card" *ngIf="!hasItem" style="text-align: center; display: flex; justify-content: center; align-items: center;">
        <h1>카트에 담긴 상품이 없습니다.</h1>
      </div>
      <div class="card cart-card" *ngFor="let item of items">
        <div class="row no-gutters">
          <div class="col-md-4">
            <img [src]="item['product']['thumbnail']['url']" class="card-img" alt="...">
          </div>
          <div class="col-md-8">
            <div class="card-body item">
              <ul class="col item-labels">
                <li class="item-label">품명</li>
                <li class="item-label">수량</li>
                <li class="item-label">가격</li>
              </ul>
              <ul class="col item-infos">
                <li class="item-info">{{item['product']['name']}}</li>
                <li class="item-info">{{item['quantity']['raw']}} 개</li>
                <li class="item-info">{{item['price']['original']['formatted']}}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
