import {Injectable} from '@angular/core';
import {Cart} from '../models/cart';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import { throwError as observableThrowError } from 'rxjs';
import {Product} from '../models';
import {catchError, map, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  public carts: Cart[] = [];
  public cart: Cart[] = [];
  private API_BASE_URL = 'https://api.clayful.io/v1';
  private API_PUBLIC_TOKEN = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjBkNDkxYWQxYWFhOGJiYjMyZGNiOWE3NTJkOTRhZGJjOGU2YTA2MDk0NDY4NzI5ZjMzMmMyMmE3NjBiMGFmMGEiLCJyb2xlIjoiY2xpZW50IiwiaWF0IjoxNTczNTI4NTI2LCJzdG9yZSI6IldRSEdORlhLNFo0Ri5UOUxTUDNSVUZTSzciLCJzdWIiOiJTQVBWSlFVMzdCWEgifQ.VDi5-QQ0WV_CaemTFeBduD0zVDukhLzyyhDEqFvyNx4';
  private options = {
    headers: new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.API_PUBLIC_TOKEN}`
    }),
    params: new HttpParams()
  };
  private test = {
    shipping: {
      postcode: '00000',
      country: 'KR',
      state: '서울특별시',
      city: '서울특별시',
      address1: '어딘가구 어딘가로 000',
      address2: '101호',
      name: {
        full: '홍길동'
      },
      mobile: '010-0000-0000',
      phone: '02-000-0000'
    },
    billing: {
      postcode: '00000',
      country: 'KR',
      state: '서울특별시',
      city: '서울특별시',
      address1: '어딘가구 어딘가로 000',
      address2: '101호',
      name: {
        full: '홍길동'
      },
      mobile: '010-0000-0000',
      phone: '02-000-0000'
    }
  };
  constructor(private http: HttpClient) {}
  add(cart: Cart): void {
    this.carts.push(cart);
  }
  getRealCart(): any {
    const body = {
      items: this.carts,
      address: this.test
    };
    return this.http
      .post<Cart[]>(`${this.API_BASE_URL}` + '/me/non-registered/cart', body, this.options)
      .pipe(
        tap((resp) =>
          console.log(resp)
        ),
        catchError(this.handleError)
      );
  }
  order(customer: any): any {
    const body = {
      items: this.carts,
      address: this.test,
      currency: 'KRW',
      paymentMethod: 'clayful-iamport',
      customer: {
        name: {
          full: customer.name || ''
        },
        email: customer.email || '',
        mobile: customer.mobile || '',
        phone: customer.mobile || ''
      },
      request: customer.request || ''
    };
    return this.http
      .post<Cart[]>(`${this.API_BASE_URL}` + '/me/non-registered/cart/checkout/order', body, this.options)
      .pipe(
        tap((resp) =>
          console.log(resp)
        ),
        catchError(this.handleError)
      );
  }
  handleError(res: HttpErrorResponse): any {
    console.error(res.error);
    return observableThrowError(res.error || 'Server error');
  }
}
