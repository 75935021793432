import { Component, OnInit } from '@angular/core';
import {CartService} from '../core/services';
import {finalize} from 'rxjs/operators';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'dfwm-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {
  items: [] = [];
  customer = {
    name: '',
    email: '',
    mobile: '',
    address: '',
    request: '',
    privacy: false
  };
  loading!: boolean;
  hasItem = false;
  ordered: object = {};
  customerForm!: FormGroup;
  constructor(private cartService: CartService) { }

  ngOnInit(): void {
    this.getCart();
    // @ts-ignore
    IMP.init('imp64035118');

    this.customerForm = new FormGroup({
      name: new FormControl(this.customer.name, [Validators.required]),
      email: new FormControl(this.customer.email, Validators.required),
      mobile: new FormControl(this.customer.mobile, Validators.required),
      address: new FormControl(this.customer.address, Validators.required),
      request: new FormControl(this.customer.request),
      privacy: new FormControl(this.customer.privacy, Validators.requiredTrue)
    });
  }
  getCart(): any {
    this.loading = true;
    this.cartService
      .getRealCart()
      .pipe(finalize(() => (this.loading = false)))
      .subscribe((data: any) => {
        if (data.cart.items.length === 0) {

        } else {
          this.hasItem = true;
          this.items = data.cart.items;
        }
        }
      );
  }
  order(): any {
    const checked = this.customerForm.get('privacy')?.value;
    if (!checked) {
      alert('개인정보 이용, 수집에 동의해주세요');
      return;
    }
    if (this.customerForm.invalid) {
      alert('구매자 정보를 정확히 입력해주세요');
      return;
    }
    if (this.customerForm.valid) {
      const man = this.customerForm.value;
      this.cartService
        .order(man)
        .pipe(finalize(() => (this.loading = false)))
        .subscribe((data: any) => {
          const ordered = data?.order;
          // @ts-ignore
          IMP.request_pay({ // param
            pg: 'kakaopay',
            pay_method: 'card',
            merchant_uid: ordered._id,
            name:           ordered._id,
            amount:         ordered.total.amount,
            currency:       ordered.currency.payment.code,
            buyer_name:     ordered.customer.name.full,
            buyer_tel:      ordered.customer.mobile || ordered.customer.phone,
            buyer_email:    ordered.customer.email || null,
          }, (rsp:any) => { // callback
            if (rsp.success) {
              console.log(rsp);
              // 결제 성공 시 로직,
              alert('결제가 성공하였습니다.');
              this.items = [];
            } else {
              // 결제 실패 시 로직,
              console.log(rsp);
              alert('결제가 실패했습니다.');
            }
          });
        });
    }
  }

}
