import { Component, OnInit } from '@angular/core';
import { faTimes, faBars } from '@fortawesome/free-solid-svg-icons';
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'dfwm-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  faTimes = faTimes;
  faBars = faBars;
  userMenus = [{
    name: '로그인',
    url: '#'
  }, {
    name: '회원가입',
    url: '#'
  }, {
    name: '마이페이지',
    url: '#'
  }, {
    name: '고객센터',
    url: '#'
  }];
  marketMenus = [{
    name: '전체 상품보기',
    url: '#'
  }, {
    name: '베스트',
    url: '#'
  }, {
    name: '이벤트',
    url: '#'
  }, {
    name: '상품후기',
    url: '#'
  }];
  constructor() { }

  ngOnInit(): void {
  }

}
